/** @jsx jsx */
import { jsx } from 'theme-ui'
import ArtPage from '../../components/art/ArtPage';

const ArtContact = () => {
    return (
        <ArtPage>
            Email: <a href="mailto:hello@patrickkalita.com">hello@patrickkalita.com</a>
        </ArtPage>
    )
}

export default ArtContact